<template>
  <main class="relative flex h-full min-h-[180vw] laptop:min-h-0 overflow-auto">
    <div
      class="absolute top-0 bottom-0 laptop:bottom-[30px] w-full flex justify-center overflow-hidden"
    >
      <div
        class="w-full laptop:w-fit laptop:h-full aspect-[619/1241] laptop:aspect-[1850/883] m-auto relative pb-0"
      >
        <img
          src="@/assets/images/home-desktop.png"
          class="h-full w-auto hidden m-auto laptop:block"
        />
        <img
          src="@/assets/images/game-mobile.png"
          class="h-full w-auto laptop:hidden m-auto"
        />
      </div>

      <img
        src="@/assets/images/vise-ball.png"
        class="absolute bottom-0 right-0 w-[73vw] laptop:hidden pointer-events-none"
      />
    </div>

    <div class="virgule"></div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      state: 0,
      currentposX: 0.6,
      timeoutId: 0,
    };
  },
  mounted() {},
};
</script>
<style lang="scss">
#pot {
  animation: linear infinite;
  animation-name: run;
  animation-duration: 2.5s;
}

@keyframes run {
  0% {
    @apply left-[35vh];
    opacity: 1;
  }
  50% {
    @apply left-[55vh];
    opacity: 0.4;
  }
  100% {
    @apply left-[35vh];
    opacity: 1;
  }
}

@media only screen and (max-width: 1023px) {
  @keyframes run {
    0% {
      @apply left-[25vw];
      opacity: 1;
    }
    50% {
      @apply left-[65vw];
      opacity: 0.4;
    }
    100% {
      @apply left-[25vw];
      opacity: 1;
    }
  }
}

@-webkit-keyframes run {
  0% {
    @apply left-[5vh] laptop:left-[35vh];
    opacity: 1;
  }
  50% {
    @apply left-[25vh] laptop:left-[55vh];
    opacity: 0.4;
  }
  100% {
    @apply left-[5vh] laptop:left-[35vh];
    opacity: 1;
  }
}

.wrapper {
  @apply w-full h-full overflow-hidden relative;
  perspective: 320vh;
  perspective-origin: 50% 22%;
  @media only screen and (max-width: 1023px) {
    perspective-origin: 50% 22%;
  }
}

.ground {
  @apply absolute top-0 w-full left-0 laptop:w-1/2 laptop:left-[28%]  h-full [transform:rotateX(90deg)_scaleY(7)] laptop:[transform:rotateX(90deg)_scaleY(5.5)] -translate-y-[50%] laptop:-translate-y-[50%];
}

.wrapper img {
  @apply absolute w-[16%] top-[35%] laptop:top-[45%] left-[55%] laptop:left-[45%];
}
</style>
