<template>
  <main class="relative flex h-full min-h-[180vw] laptop:min-h-0 overflow-auto">
    <div
      v-if="state < 2 && is_touch_enabled()"
      class="fixed inset-0 z-20"
      @touchstart="handleMouseDown"
      @touchend="handleMouseUp"
      @touchmove="handleTouchMove"
    ></div>

    <div
      v-if="state < 2 && !is_touch_enabled()"
      class="fixed inset-0 z-20"
      @mousedown.left="handleMouseDown"
    ></div>

    <div
      class="absolute top-0 bottom-0 laptop:bottom-[30px] w-full flex justify-center overflow-hidden"
    >
      <div
        class="w-full laptop:w-fit laptop:h-full aspect-[619/1241] laptop:aspect-[1234/1132] m-auto relative pb-0"
      >
        <div class="wrapper absolute inset-0" ref="wrapper">
          <div class="ground">
            <div class="arrow" ref="arrow">
              <img
                class="arrow_img"
                :class="{ 'opacity-0': state == 2 }"
                src="@/assets/images/arrow.png"
              />
            </div>
          </div>
        </div>

        <img
          :class="{ 'opacity-0': state == 2 }"
          src="@/assets/images/vise-ball.png"
          class="ball_img"
          ref="ball"
        />

        <div
          v-if="state == 0"
          class="absolute laptop:bottom-[43vh] text-bleu laptop:left-[5vh] top-[85vw] laptop:top-auto left-[7.5vw]"
        >
          <h2 class="text-[4.8vw] font-extrabold laptop:text-[4.5vh]">
            LE JEU
          </h2>
          <p
            class="text-[3.5vw] leading-[4.5vw] laptop:text-[2.5vh] laptop:leading-[2.6vh]"
          >
            Je maintiens la flèche<br />et je vise le cochonnet <br />pour
            lancer la boule.
          </p>
        </div>

        <img
          v-if="state == 0"
          src="@/assets/images/main.png"
          class="absolute bottom-[60vw] laptop:bottom-[22vh] left-0 w-[22vw] laptop:w-[15vh]"
          id="pot"
        />

        <img
          :class="{ 'opacity-0': state == 2 }"
          src="@/assets/images/game-desktop.png"
          class="h-full w-auto hidden m-auto laptop:block"
        />
        <img
          :class="{ 'opacity-0': state == 2 }"
          src="@/assets/images/game-mobile.png"
          class="h-full w-auto laptop:hidden m-auto"
        />

        <video
          ref="video"
          v-show="state == 2"
          class="absolute inset-x-0 top-0 h-full mx-auto w-auto pointer-events-auto object-contain laptop:hidden"
          preload
          playsinline
          muted
          autoplay
          disablePictureInPicture
        >
          <source src="/petan1.webm" type="video/webm" />
          <source src="/petan1.mp4" type="video/mp4" />
          <source src="/petan1.mov" type="video/quicktime" />

          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    <video
      ref="video_desktop"
      v-show="state == 2"
      class="absolute mx-auto inset-x-0 h-full pointer-events-auto object-contain hidden laptop:block bottom-0 pb-10"
      preload
      playsinline
      muted
      autoplay
      disablePictureInPicture
    >
      <source src="/petan-desk.webm" type="video/webm" />
      <source src="/petan-desk.mp4" type="video/mp4" />
      <source src="/petan-desk.mov" type="video/quicktime" />

      Your browser does not support the video tag.
    </video>
    <div class="virgule"></div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      state: 0,
      currentposX: 0.6,
      timeoutId: 0,
    };
  },
  mounted() {
    if (!this.is_touch_enabled()) {
      window.addEventListener("mousemove", this.handleMouseMove);
      window.addEventListener("mouseup", this.handleMouseUp);
    }
  },
  watch: {
    currentposX() {
      this.$refs.arrow.style.transform = `translateX(${
        -50 + (this.currentposX - 0.75) * 150
      }%)`;

      this.$refs.ball.style.transform = `translateX(${
        -50 + (this.currentposX - 0.44) * 100
      }%)`;
    },
  },
  methods: {
    is_touch_enabled() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    },
    handleMouseMove(event) {
      if (this.state == 1 && !this.timeoutId) {
        const posX = event.clientX;
        const rect = this.$refs.wrapper.getBoundingClientRect();
        this.currentposX = (posX - rect.left) / rect.width;
      }
    },
    handleTouchMove(event) {
      if (this.state == 1 && !this.timeoutId) {
        const posX = event.touches[0].clientX;
        const rect = this.$refs.wrapper.getBoundingClientRect();
        this.currentposX = (posX - rect.left) / rect.width;
      }
    },
    handleMouseUp() {
      this.timeoutId = setTimeout(this.onshoot, 1000);
    },
    handleMouseDown() {
      document.body.style.overflow = "hidden";
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = 0;
      }
      this.state = 1;
    },
    onshoot() {
      if (this.state == 2) return;
      this.state = 2;
      document.body.style.overflow = "auto";
      const videos = [this.$refs.video, this.$refs.video_desktop];
      for (const video of videos) {
        if (video) {
          video.load();
          video.play();
        }
      }

      setTimeout(() => {
        this.$router.push("/gain");
      }, 5000);
    },
  },
};
</script>
<style lang="scss">
#pot {
  animation: linear infinite;
  animation-name: run;
  animation-duration: 2.5s;
}

@keyframes run {
  0% {
    @apply left-[35vh];
    opacity: 1;
  }
  50% {
    @apply left-[55vh];
    opacity: 0.4;
  }
  100% {
    @apply left-[35vh];
    opacity: 1;
  }
}

@media only screen and (max-width: 1023px) {
  @keyframes run {
    0% {
      @apply left-[25vw];
      opacity: 1;
    }
    50% {
      @apply left-[65vw];
      opacity: 0.4;
    }
    100% {
      @apply left-[25vw];
      opacity: 1;
    }
  }
}

@-webkit-keyframes run {
  0% {
    @apply left-[5vh] laptop:left-[35vh];
    opacity: 1;
  }
  50% {
    @apply left-[25vh] laptop:left-[55vh];
    opacity: 0.4;
  }
  100% {
    @apply left-[5vh] laptop:left-[35vh];
    opacity: 1;
  }
}

.ball_img {
  @apply absolute bottom-[7%] laptop:bottom-[-7%] -translate-x-[-15%] laptop:-translate-x-[14%] laptop:left-1/2 right-0 w-[55%] laptop:w-[40%]  pointer-events-none;
}

.wrapper {
  @apply w-full h-full overflow-hidden relative;
  perspective: 320vh;
  perspective-origin: 50% 22%;

  .ground {
    @apply absolute top-0 w-full left-0 laptop:w-1/2 laptop:left-[28%]  h-full [transform:rotateX(90deg)_scaleY(7)] laptop:[transform:rotateX(90deg)_scaleY(5.5)] -translate-y-[50%] laptop:-translate-y-[50%];

    .arrow {
      @apply absolute w-[16%]   top-[35%] laptop:top-[45%] left-[55%] laptop:left-[50%] -translate-x-[50%];

      .arrow_img {
        @apply absolute  w-full  left-1/2 -translate-x-1/2;
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    perspective-origin: 50% 22%;
  }
}
</style>
