<template>
  <footer class="relative m-auto z-30">
    <div class="w-fit text-center m-auto px-0 relative laptop:flex">
      <ul
        class="flex gap-2 items-center justify-center pb-[2%] tablet:pb-3 tablet:-mt-6"
      >
        <li class="w-[15%] tablet:w-20">
          <a
            :href="`https://www.lapostemobile.fr/?utm_source=partenaire&utm_medium=jeu&utm_campaign=fin-parcours-jeu`"
            target="_blank"
            ><img src="@/assets/images/logo.png" class="w-full"
          /></a>
        </li>
        <li
          class="font-extrabold text-bleu text-[3.5vw] tablet:text-xl laptop:text-lg laptop:w-44"
        >
          SUIVEZ-NOUS&nbsp;:
        </li>
        <li class="laptop:mr-2">
          <a href="https://www.facebook.com/LaPosteMobile" target="_blank">
            <span class="fill-bleu text-[8vw] tablet:text-5xl">
              <svg
                class="w-[1em] inline-block"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
                <path
                  d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"
                />
              </svg>
            </span>
          </a>
        </li>
        <li class="laptop:mr-2">
          <a
            href="https://www.instagram.com/lapostemobile/?hl=fr"
            target="_blank"
          >
            <span
              class="w-[8vw] tablet:w-12 h-[8vw] tablet:h-12 bg-bleu rounded-full flex justify-center items-center p-[25%]"
            >
              <svg
                class="w-full fill-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
                <path
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                />
              </svg>
            </span>
          </a>
        </li>
      </ul>
      <ul
        class="flex justify-center gap-2 text-[3vw] tablet:text-base mb-2 w-full laptop:ml-2 laptop:-mt-2"
      >
        <li>
          <a
            class="text-bleuclair"
            href="https://oxoformdocs.fra1.digitaloceanspaces.com/664b5072ad4380e0e8943edc/mentions.pdf"
            target="_blank"
            >Mentions légales</a
          >
        </li>
        <li>-</li>
        <li>
          <a
            class="text-bleuclair"
            href="https://oxoformdocs.fra1.digitaloceanspaces.com/664b5072ad4380e0e8943edc/reglement.pdf"
            target="_blank"
            >Règlement</a
          >
        </li>
        <li>-</li>
        <li>
          <a class="text-bleuclair" href="javascript:Didomi.preferences.show()"
            >Préférences des cookies</a
          >
        </li>
      </ul>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    utmparams: String,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted() {
    const utms = this.utmparams;

    if (!utms || utms == "null" || utms == null) {
      this.utms = "";
    } else {
      this.utms = `?${utms}`;
    }
  },
  data() {
    return {
      utms: "",
    };
  },
};
</script>
