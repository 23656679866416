<template>
  <div id="app">
    <router-view :bdp="bdp" />
    <FooterView :utmparams="params" />
  </div>
</template>
<script>
import FooterView from "@/components/FooterView.vue";

export default {
  components: {
    FooterView,
  },
  data() {
    return {
      bdp: null,
      params: null,
    };
  },

  mounted() {
    this.bdp = this.$route.query.utm_source;
    if (this.bdp == "bdp") {
      sessionStorage.setItem("bdp", this.bdp);
    }

    const getParams = window.location.search;
    if (getParams) {
      this.params = getParams;
      sessionStorage.setItem("utmParams", getParams);

      const params = new URLSearchParams(getParams);
      const paramsObj = {};
      for (let param of params) {
        paramsObj[param[0]] = param[1];
      }
      sessionStorage.setItem("utmJsonParams", JSON.stringify(paramsObj));
    }
  },
};
</script>
