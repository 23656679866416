<template>
  <div class="m-auto">
    <div class="laptop:h-full laptop:w-full 2xl:max-w-[1920px] m-auto">
      <div
        class="relative hidden laptop:block mb-[1%] 2xl:mb-1 laptop:-mb-10 laptop:h-full laptop:w-full"
      >
        <img
          src="@/assets/images/home-desktop.png"
          class="w-full mt-[3%] 2xl:mt-10"
        />

        <div class="absolute bottom-[10%] left-[3%] w-[40%] text-center">
          <router-link to="/form"
            ><button
              class="button blue !w-[35%] !text-[1.2vw] 2xl:!text-xl !font-extrabold !px-[5%] !py-[3%] m-auto"
            >
              JE JOUE
            </button></router-link
          >
          <p class="text-[.8vw] mt-[4%] 2xl:mt-12 2xl:text-lg">
            Jeu organisé par La Poste Telecom du 01/07 au 25/08/2024 inclus.
          </p>
        </div>
      </div>
      <div
        class="relative pb-[4%] tablet:pb-6 tablet:max-w-xl laptop:max-w-none laptop:mb-0 2xl:max-w-[1536px] tablet:m-auto"
      >
        <div class="laptop:hidden">
          <img
            src="@/assets/images/encart.png"
            class="w-[68%] tablet:w-[350px] laptop:w-[40%] tablet:mt-10 mt-[8%] m-auto"
          />

          <img
            src="@/assets/images/home-m.png"
            class="w-full mt-[1%] tablet:w-[500px] laptop:w-[40%] tablet:mt-2 m-auto tablet:opacity-0 laptop:opacity-100"
          />
        </div>

        <div class="absolute bottom-0 w-full text-center pb-[5%] laptop:hidden">
          <router-link to="/form"
            ><button
              class="button blue !w-[37vw] tablet:!w-52 !text-[4vw] tablet:!text-2xl !font-extrabold !px-[2.5vw] tablet:!px-10 !py-[2.5vw] tablet:!py-3 m-auto"
            >
              JE JOUE
            </button></router-link
          >
          <p
            class="text-[2.5vw] tablet:text-base my-[3%] tablet:my-4 tablet:leading-5"
          >
            Jeu organisé par La Poste Telecom <br />du 01/07 au 25/08/2024
            inclus.
          </p>
        </div>
      </div>

      <div class="virgule mb-[-.1%]"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      host: undefined,
    };
  },

  mounted() {
    sessionStorage.removeItem("subid");
    sessionStorage.removeItem("uid");
    sessionStorage.removeItem("postal_code");

    this.host = window.location.host;
    const queryParams = this.$route.query;

    if (queryParams) {
      sessionStorage.setItem("utms", JSON.stringify(queryParams));
    }
  },
};
</script>
<style>
.bg-home {
  @media only screen and (max-width: 1023px) {
    background-image: url("@/assets/images/full-home-mobile.png");
    background-repeat: no-repeat;
    background-size: 700px auto;
    background-position: top 350px center;
  }

  @media only screen and (max-width: 610px) {
    background-image: none;
  }
}
</style>
