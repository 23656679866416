import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import DemoView from "../views/DemoView.vue";
import GameView from "../views/GameView.vue";
import GainView from "../views/GainView.vue";
import TestView from "../views/TestView.vue";
import AnimationView from "../views/AnimationView.vue";
import UnsubscribeView from "../views/UnsubscribeView.vue";
import NotFoundView from "../views/404View.vue";
import Home2View from "../views/Home2View.vue";
import FinishView from "../views/FinishView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "NotFoundView",
    component: NotFoundView,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/home",
    name: "home2",
    component: Home2View,
  },
  {
    path: "/finish",
    name: "finish",
    component: FinishView,
  },
  {
    path: "/test",
    name: "test",
    component: TestView,
  },
  {
    path: "/gain",
    name: "gain",
    component: GainView,
  },
  {
    path: "/deja-gagne",
    name: "deja-gagne",
    component: GainView,
    props: { alreadyWon: true },
  },
  {
    path: "/animation",
    name: "animation",
    component: AnimationView,
  },
  {
    path: "/unsubscribe/:_id/:_secu",
    name: "unsubscribe",
    component: UnsubscribeView,
  },
  {
    path: "/demo",
    name: "demo",
    component: DemoView,
  },
  {
    path: "/game",
    name: "game",
    component: GameView,
  },
  // {
  //   path: "/form",
  //   name: "form",
  //   component: FormView,
  // },
  {
    path: "/status/:_id/:_secu",
    name: "status",
    component: () => import("../views/UpdateView.vue"),
  },
  {
    path: "/__testvideo",
    name: "testvideo",
    component: () => import("../views/TestVideo.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
