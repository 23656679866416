<template>
  <main class="m-auto relative bg-home laptop:flex laptop:items-center">
    <HomeView />
  </main>
</template>
<script>
import HomeView from "@/components/HomeView.vue";

export default {
  components: {
    HomeView,
  },
};
</script>
