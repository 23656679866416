<template>
  <main
    class="m-auto relative laptop:flex laptop:items-center"
    :class="{ 'bg-home': step === 1, 'bg-finish': step === 3 }"
  >
    <HomeView v-if="step === 1" />
    <WaitView v-else-if="step === 2" />
    <FinishView v-else-if="step === 3" />
  </main>
</template>
<script>
import HomeView from "@/components/HomeView.vue";
import WaitView from "@/components/WaitView.vue";
import FinishView from "@/components/FinishView.vue";

export default {
  data() {
    return {
      step: 3,
    };
  },
  mounted() {
    const now = new Date();

    const julyFirst = new Date("2024-07-01 08:00");
    const opEnd = new Date("2024-08-25 23:59");

    if (now >= julyFirst) {
      this.step = 1;
    }

    if (now >= opEnd) {
      this.step = 3;
    }
  },
  components: {
    HomeView,
    WaitView,
    FinishView,
  },
};
</script>
