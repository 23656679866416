<template>
  <main class="m-auto relative bg-home laptop:flex laptop:items-center">
    <FinishView />
  </main>
</template>
<script>
import FinishView from "@/components/FinishView.vue";

export default {
  components: {
    FinishView,
  },
};
</script>
