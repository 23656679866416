<template>
  <main
    class="m-auto relative w-full laptop:flex laptop:items-center"
    v-if="lot"
  >
    <div class="w-full">
      <div
        class="relative pb-[4%] 2xl:pb-20 tablet:max-w-lg m-auto laptop:flex laptop:max-w-none laptop:flex-wrap 2xl:max-w-[1700px]"
      >
        <div class="laptop:w-[49%]">
          <img
            v-if="lot == '2mois'"
            src="@/assets/images/2mois.png"
            class="w-[65%] tablet:mb-0 tablet:w-[400px] laptop:w-[80%] 2xl:w-[600px] mt-[2%] laptop:mt-[8%] 2xl:mt-32 tablet:mt-8 m-auto"
          />
          <div
            :class="{ 'mt-[-11%]': lot == '2mois' && bdp }"
            v-if="lot == '2mois' && !alreadyWon"
            class="hidden laptop:block"
          >
            <p
              v-if="bdp"
              class="text-[4.5vw] tablet:text-2xl laptop:text-[1.75vw] text-center mt-[-7%] tablet:-mt-14 laptop:mt-[2vw]"
            >
              Votre code promo&nbsp;:
            </p>
            <div
              v-if="bdp"
              class="bg-white text-bleu font-semibold py-1 my-3 w-fit m-auto px-[7vw] 2xl:px-32 laptop:py-[.5vw] 2xl:py-3 laptop:my-[1vw] 2xl:my-2 text-[5vw] tablet:text-xl laptop:text-[1.7vw] 2xl:text-[40px] flex justify-center items-center"
            >
              {{ code }}
            </div>

            <p
              v-if="bdp"
              class="text-[3vw] tablet:text-lg laptop:text-[1vw] laptop:leading-[1vw] leading-[4vw] tablet:leading-5 text-center laptop:px-[6vw]"
            >
              Votre code promo vous a également été envoyé par email.
              <br />
              <small>(Pensez à vérifier vos spams)</small>
            </p>

            <p
              v-if="bdp"
              class="w-full bg-transparent text-bleu border-2 border-bleu text-[3.5vw] tablet:text-base laptop:text-[1.2vw] text-center py-1 mt-1 tablet:mt-4 laptop:py-[1%] laptop:w-fit laptop:m-auto laptop:mt-[2vw] laptop:px-[2%]"
            >
              Souscrivez dès maintenant avec un conseiller&nbsp;!
            </p>
          </div>
          <img
            v-if="lot == '200'"
            src="@/assets/images/200.png"
            class="w-[80%] tablet:w-[400px] laptop:w-[75%] 2xl:w-[700px] mt-[8%] laptop:mt-[8%] 2xl:mt-16 tablet:mt-8 m-auto"
          />
          <img
            v-if="lot == '1000'"
            src="@/assets/images/1000.png"
            class="w-[80%] tablet:w-[400px] laptop:w-[75%] 2xl:w-[700px] mt-[8%] laptop:mt-[8%] 2xl:mt-16 tablet:mt-8 m-auto"
          />
          <p
            v-if="lot != '200' && lot != '1000' && !bdp && !alreadyWon"
            class="hidden laptop:block w-full 2xl:w-[600px] 2xl:mx-auto text-[1.1vw] 2xl:text-xl leading-[1.4vw] px-[20%] 2xl:px-[12%] 2xl:mt-4 text-center"
          >
            Votre code promo vous a été envoyé par email à l'adresse indiquée.
            <br class="hidden 2xl:block" />
            <small>(Pensez à vérifier vos spams)</small>
          </p>

          <div
            v-if="lot === '2mois' && alreadyWon"
            class="flex flex-col justify-center"
          >
            <div
              class="text-[4.5vw] tablet:text-2xl laptop:text-[1.5vw] 2xl:text-[40px] text-center mb-[1em]"
            >
              <!-- 2 mois -->
              Vous avez déjà GAGNÉ&nbsp;!
            </div>
            <div
              class="text-[4vw] mt-0 tablet:mt-0 tablet:text-lg laptop:text-[1.25vw] 2xl:text-[28px] 2xl:mt-8 laptop:leading-[1.5vw] 2xl:leading-tight leading-[4vw] tablet:leading-5 text-center laptop:px-[6vw] 2xl:px-0"
            >
              Vous ne pouvez jouer qu’une seule fois,
              <br />
              votre code gagnant est
            </div>
            <div
              class="bg-white text-bleu font-semibold py-1 my-3 w-fit m-auto px-[7vw] 2xl:px-32 laptop:py-[.5vw] 2xl:py-3 laptop:my-[1vw] 2xl:my-2 text-[5vw] tablet:text-xl laptop:text-[1.7vw] 2xl:text-[40px] flex justify-center items-center"
            >
              {{ code }}
            </div>
          </div>

          <p
            v-if="lot !== '2mois' && !bdp && !alreadyWon"
            class="hidden font-normal mb-6 laptop:block w-[67%] 2xl:w-[600px] text-[1.1vw] leading-[1.4vw] pl-[12%] 2xl:pl-20 2xl:text-[20px] 2xl:leading-tight"
          >
            Votre code promo pour les 2 mois de forfait mobile offerts vous a
            été envoyé par email à l'adresse renseignée.
            <small>(Pensez à vérifier vos spams)</small>
          </p>
        </div>

        <div
          class="tablet:px-6 tablet:mb-7 laptop:w-[49%] px-[6vw] laptop:px-[2vw] 2xl:pl-20 2xl:pr-0 flex flex-col justify-center"
        >
          <div
            v-if="lot !== '2mois' && alreadyWon"
            class="flex flex-col justify-center laptop:py-10"
          >
            <div
              class="text-[4.5vw] tablet:text-2xl laptop:text-[1.5vw] 2xl:text-[40px] text-center mb-[1em] pt-[2em]"
            >
              <!-- Intersport -->
              Vous avez déjà GAGNÉ&nbsp;!
            </div>
            <div
              class="text-[4vw] mt-0 tablet:mt-0 tablet:text-lg laptop:text-[1.25vw] 2xl:text-[28px] 2xl:mt-8 laptop:leading-[1.5vw] 2xl:leading-tight leading-[4vw] tablet:leading-5 text-center laptop:px-[6vw] 2xl:px-0"
            >
              Vous ne pouvez jouer qu’une seule fois,
              <br />
              votre code gagnant est
            </div>
            <div
              class="bg-white text-bleu font-semibold py-1 my-3 w-fit m-auto px-[7vw] 2xl:px-32 laptop:py-[.5vw] 2xl:py-3 laptop:my-[1vw] 2xl:my-2 text-[5vw] tablet:text-xl laptop:text-[1.7vw] 2xl:text-[40px] flex justify-center items-center"
            >
              {{ code }}
            </div>
          </div>

          <div
            v-if="bdp && !alreadyWon"
            class="w-full max-w-[70%] laptop:max-w-none text-center m-auto"
            :class="`lot_${lot}`"
          >
            <p
              class="text-[4.5vw] tablet:text-2xl laptop:text-[1.6vw] 2xl:text-[30px] laptop:mt-[12%] 2xl:mt-20 tablet:mt-2"
            >
              Votre code promo&nbsp;:
            </p>
            <div
              class="bg-white text-bleu font-semibold py-1 my-3 w-fit m-auto px-[7vw] 2xl:px-32 laptop:py-[.5vw] 2xl:py-3 laptop:my-[1vw] 2xl:my-2 text-[5vw] tablet:text-xl laptop:text-[1.7vw] 2xl:text-[40px] flex justify-center items-center"
            >
              {{ code }}
            </div>
          </div>
          <p
            v-if="bdp && !alreadyWon"
            :class="`lot_${lot}`"
            class="text-[3vw] mt-[2vw] tablet:mt-0 tablet:text-base laptop:text-[1vw] 2xl:text-[22px] 2xl:mt-8 laptop:leading-[1vw] 2xl:leading-tight leading-[4vw] tablet:leading-5 text-center laptop:px-[6vw] 2xl:px-0"
          >
            Votre code promo
            <template v-if="lot !== '2mois'">
              pour les 2 mois de forfait mobile offerts
            </template>
            vous a également été envoyé par email.
            <small>(Pensez à vérifier vos spams)</small>
          </p>
          <p
            v-else-if="!alreadyWon"
            :class="`lot_${lot} mode_${bdp}`"
            class="text-[3.5vw] tablet:text-lg laptop:text-[1.2vw] laptop:leading-[1.4vw] leading-[4vw] tablet:leading-5 text-center laptop:px-[6vw] mt-4"
          >
            Votre code promo
            <template v-if="lot !== '2mois'">
              pour les 2 mois de forfait mobile offerts
            </template>
            vous a été envoyé par email à l'adresse indiquée.
            <small>(Pensez à vérifier vos spams)</small>
          </p>
          <p
            v-if="bdp && !alreadyWon"
            :class="`lot_${lot}`"
            class="w-full bg-transparent text-bleu border-2 border-bleu text-[3.2vw] tablet:text-base laptop:text-[1.3vw] 2xl:text-[22px] text-center py-1 mt-4 tablet:mt-4 laptop:mt-[3%] laptop:py-[1.5%] laptop:w-fit laptop:m-auto laptop:my-[3vw] 2xl:my-12 laptop:px-[2%] 2xl:px-4"
          >
            Souscrivez dès maintenant avec un conseiller&nbsp;!
          </p>

          <div v-if="lot == '200' || lot == '1000'">
            <div
              v-if="!bdp"
              :class="{ 'tablet:mt-16': !alreadyWon }"
              class="w-full text-center laptop:items-center laptop:justify-center laptop:gap-4 mb-8 laptop:mt-0 mt-0 laptop:hidden"
            >
              <p>
                <a
                  href="https://www.lapostemobile.fr/offres-mobiles/forfaits-sans-engagement?utm_source=partenaire&utm_medium=jeu&utm_campaign=fin-parcours-jeu"
                  target="_blank"
                  ><button
                    class="button blue !w-[57vw] 2xl:w-fit laptop:!w-fit mb-4 mt-4 tablet:!w-96 laptop:!text-[1vw] 2xl:!text-[20px] !text-[4vw] tablet:!text-base !font-extrabold !px-[2.5vw] tablet:!px-10 !py-[2.5vw] 2xl:!px-4 laptop:!py-[1vw] 2xl:!py-4 tablet:!py-3 m-auto"
                  >
                    J'EN PROFITE EN LIGNE
                  </button></a
                >
              </p>

              <p>
                <a
                  href="https://localiser.laposte.fr/?q=46.8290826,-1.8913022&jesuis=particulier&contact=vente&qp=59500&localisateurLaPosteMobile=4185&localisateurLaPosteMobile=4186&localisateurLaPosteMobile=4187&r=31&per=50&l=fr"
                  target="_blank"
                  ><button
                    class="button blue !w-[74vw] 2xl:!w-fit laptop:!w-fit tablet:!w-96 !text-[4vw] laptop:!text-[1vw] 2xl:!text-[20px] tablet:!text-base !font-extrabold !px-[2.5vw] tablet:!px-10 laptop:!px-[2vw] 2xl:!px-4 laptop:!py-[1vw] 2xl:!py-4 !py-[2.5vw] tablet:!py-3 m-auto"
                  >
                    JE VAIS EN BUREAU DE POSTE
                  </button></a
                >
              </p>
            </div>

            <p
              :class="{
                'mt-[8vw] 2xl:mt-8': !bdp && !alreadyWon,
                'mt-12': alreadyWon,
              }"
              class="mt-7 font-extrabold laptop:mb-[2vw] 2xl:mb-[35px] text-[4vw] tablet:text-xl laptop:text-[1.3vw] leading-[4.5vw] tablet:leading-6 laptop:leading-[1.9vw] 2xl:leading-[29px] 2xl:text-[23px]"
            >
              POUR BÉNÉFICIER DE VOTRE CARTE CADEAU INTERSPORT,
              <br class="hidden laptop:block" />
              3 ÉTAPES SIMPLES&nbsp;:
            </p>

            <ul
              class="mt-6 text-[3.5vw] tablet:text-xl laptop:text-[1.2vw] 2xl:text-[25px] leading-[4vw] tablet:leading-5"
            >
              <li class="tuto_step">
                <img
                  src="@/assets/images/tuto1.png"
                  class="h-[7vw] tablet:h-10 laptop:h-[2.5vw] 2xl:h-[50px] w-auto"
                />
                <p class="pl-3 laptop:leading-[1.2vw] 2xl:leading-[29px]">
                  <b class="font-extrabold">Souscrivez un forfait</b> La Poste
                  Mobile<br class="hidden laptop:block" />
                  en renseignant le code reçu par email.
                </p>
              </li>
              <li class="tuto_step">
                <img
                  src="@/assets/images/tuto2.png"
                  class="h-[7vw] tablet:h-10 laptop:h-[2.5vw] 2xl:h-[50px] w-auto"
                />
                <p class="pl-3 laptop:leading-[1.2vw] 2xl:leading-[29px]">
                  Après validation de votre souscription, vous recevrez un email
                  pour
                  <b class="font-extrabold">renseigner votre adresse postale</b
                  >.
                </p>
              </li>
              <li class="tuto_step">
                <img
                  src="@/assets/images/tuto3.png"
                  class="h-[6vw] tablet:h-8 laptop:h-[2vw] 2xl:h-[40px] w-auto"
                />
                <p class="pl-3 laptop:leading-[1.2vw] 2xl:leading-[29px]">
                  La <b class="font-extrabold">carte cadeau Intersport</b> vous
                  sera <br class="hidden laptop:block" />envoyée par courrier.
                </p>
              </li>
            </ul>
          </div>

          <div class="w-full" v-if="lot == '2mois'">
            <div class="w-full text-right mt-2 flex justify-center">
              <img
                src="@/assets/images/win.png"
                class="w-[70%] laptop:hidden"
              />
              <img
                src="@/assets/images/win2.png"
                class="hidden laptop:block max-w-[400px] 2xl:w-[600px] 2xl:-ml-28 2xl:max-w-none"
              />
            </div>
            <div
              class="w-full text-right mt-2 flex justify-start laptop:hidden"
            >
              <img
                src="@/assets/images/win-boules.png"
                class="w-[28%] -mt-[20%] ml-[-10%]"
              />
            </div>
          </div>

          <div
            v-if="!bdp"
            :class="{ hidden: lot == '200' || lot == '1000' }"
            class="laptop:flex w-full text-center laptop:items-center laptop:justify-center laptop:gap-4 tablet:mt-16 mb-6 laptop:mt-0 mt-0"
          >
            <p>
              <a
                :href="`https://www.lapostemobile.fr/offres-mobiles/forfaits-sans-engagement?utm_source=partenaire&utm_medium=jeu&utm_campaign=fin-parcours-jeu`"
                target="_blank"
                ><button
                  class="button blue !w-[57vw] 2xl:w-fit laptop:!w-fit mb-4 mt-4 tablet:!w-96 laptop:!text-[1vw] 2xl:!text-[20px] !text-[4vw] tablet:!text-base !font-extrabold !px-[2.5vw] tablet:!px-10 !py-[2.5vw] 2xl:!px-4 laptop:!py-[1vw] 2xl:!py-4 tablet:!py-3 m-auto"
                >
                  J'EN PROFITE EN LIGNE
                </button></a
              >
            </p>

            <p>
              <a
                :href="`https://localiser.laposte.fr/?q=46.8290826,-1.8913022&jesuis=particulier&contact=vente&qp=${cp}&localisateurLaPosteMobile=4185&r=3&per=50&l=fr`"
                target="_blank"
                ><button
                  class="button blue !w-[74vw] 2xl:!w-fit laptop:!w-fit tablet:!w-96 !text-[4vw] laptop:!text-[1vw] 2xl:!text-[20px] tablet:!text-base !font-extrabold !px-[2.5vw] tablet:!px-10 laptop:!px-[2vw] 2xl:!px-4 laptop:!py-[1vw] 2xl:!py-4 !py-[2.5vw] tablet:!py-3 m-auto"
                >
                  JE VAIS EN BUREAU DE POSTE
                </button></a
              >
            </p>
          </div>
        </div>
      </div>

      <div class="virgule"></div>
    </div>
  </main>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  props: {
    alreadyWon: Boolean,
  },
  data() {
    return {
      lot: "2mois",
      code: "XXXXXXX",
      bdp: false,
      cp: undefined,
      utmparams: "",
    };
  },
  async mounted() {
    try {
      const lot = this.$route.query.lot;
      const bdp = this.$route.query.utm_source;

      let utms = sessionStorage.getItem("utms");
      let cp = sessionStorage.getItem("postal_code");
      this.utmparams = sessionStorage.getItem("utmParams");
      if (!this.utmparams) {
        this.utmparams = "";
      }

      if (cp) {
        this.cp = cp;
      }

      if (utms) {
        utms = JSON.parse(utms);
        if (utms.utm_source === "bdp") {
          this.bdp = true;
        }
      }

      if (bdp == "bdp") {
        this.bdp = true;
      }

      if (lot == "2mois" || lot == "200" || lot == "1000") {
        this.lot = lot;
        return;
      }

      let subid = sessionStorage.getItem("subid");
      let uid = sessionStorage.getItem("uid");

      if (subid && uid) {
        let { data } = await axios.get(
          `${process.env.VUE_APP_ROOT_API}/api/v2/submissions/${subid}?uid=${uid}`
        );

        if (!data) {
          this.$router.push("/");
          return;
        }

        if (data.code) {
          this.code = data.code;
        }

        if (data && (data.lot == "200" || data.lot == "1000")) {
          this.lot = data.lot;
        } else {
          this.lot = "2mois";
        }
      } else {
        this.$router.push("/");
      }
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style lang="scss">
.grecaptcha-badge {
  visibility: hidden !important;
}
</style>

<style lang="scss" scoped>
.lot_2mois,
.mode_false {
  @apply laptop:hidden;
}

.tuto_step {
  @apply mb-[4vw] tablet:mb-5 laptop:mb-[2vw] 2xl:mb-[45px] grid grid-cols-[9vw,_1fr] tablet:grid-cols-[3rem,_1fr] laptop:grid-cols-[3vw,_1fr]  2xl:grid-cols-[4rem,_1fr] items-center max-w-[29em];
}
</style>
