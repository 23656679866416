<template>
  <main
    class="text-bleu m-auto relative pb-[11%] laptop:pb-[7%] laptop:flex laptop:items-center"
  >
    <div
      v-if="load"
      class="bg-black/50 fixed w-full inset-0 z-50 flex justify-center items-center"
    >
      <div class="bg-white max-w-7xl p-8 mx-4 rounded">
        <img src="@/assets/images/logo.png" class="max-w-[150px] m-auto mb-4" />
        Merci de bien vouloir patienter...
        <div class="loading-wrapper mt-2">
          <div class="loader"></div>
        </div>
      </div>
    </div>
    <div class="max-w-md tablet:max-w-none m-auto">
      <h1
        class="mt-[20%] tablet:mt-20 laptop:mt-[3%] w-full text-center text-[7.5vw] tablet:text-3xl text-[#006DC0] font-extrabold"
      >
        DÉSINSCRIPTION
      </h1>

      <div v-if="!unsubscribed">
        <p
          class="text-[4vw] bg-white py-2 rounded-md text-sm tablet:text-base leading-[5vw] tablet:leading-5 mt-[3%] tablet:mt-6 mb-[5%] tablet:mb-4 tablet:max-w-none m-auto text-center"
        >
          Votre adresse email&nbsp;: {{ email }}<br class="tablet:hidden" />
        </p>
        <p class="max-w-xl text-justify py-4 px-4">
          Pour vous désinscrire, veuillez cliquer sur le bouton ci-dessous. Cela
          vous permettra de ne plus recevoir d'e-mails de notre part. Si vous
          avez des questions ou des préoccupations, n'hésitez pas à nous
          contacter.
        </p>

        <button
          @click="unsubscribe"
          class="button blue !w-fit !m-auto !px-6 !min-w-0 uppercase"
        >
          JE&nbsp;ME&nbsp;désinscris
        </button>
      </div>
      <div v-else class="text-center py-4">
        Vous n'êtes actuellement pas inscrit à notre liste de diffusion.
      </div>
      <div class="virgule"></div>
    </div>
  </main>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      load: false,
      hiderror: true,
      email: undefined,
      unsubscribed: false,
    };
  },
  async mounted() {
    try {
      const subid = this.$route.params._id;
      const uid = this.$route.params._secu;

      if (subid && uid) {
        const { data } = await axios.get(
          `${process.env.VUE_APP_ROOT_API}/api/v2/submissions/${subid}?uid=${uid}`
        );

        if (data && data._id) {
          this.email = data.email;
          if (data.opt_in_1) this.unsubscribed = true;
        } else {
          this.openError();
        }
      }
    } catch (error) {
      this.openError();
    }
  },
  methods: {
    async unsubscribe() {
      try {
        this.load = true;

        const datas = {
          _id: this.$route.params._id,
          uid: this.$route.params._secu,
        };

        await axios.post(
          `${process.env.VUE_APP_ROOT_API}/api/operations-custom/jeu-lapostemobile/unsubscribe`,
          datas
        );

        this.load = false;
        this.unsubscribed = true;
      } catch (error) {
        this.load = false;
        console.log(error);
      }
    },
    openError() {
      this.load = false;
      this.$router.push("/");
    },
  },
};
</script>

<style>
.grecaptcha-badge {
  visibility: hidden !important;
}

.valider {
  font-weight: 900 !important;
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.loading-wrapper {
  height: 10px;
  width: 90%;

  margin-inline: 5%;

  background: #cfd8dc;
  border-radius: 5px;

  overflow: hidden;
}
.loader {
  background-color: #ffcb00;
  height: 100%;
  width: 80%;
  animation: loading 2s ease infinite;
}
@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  1% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(130%);
  }
}
</style>
